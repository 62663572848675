import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Form, Input, Row, Typography } from 'antd';

import { NAME_VALIDATION_PATTERN } from 'utils/form';
import { PhoneFormItem } from 'components/common/forms/phone';
import { SelectOption } from 'utils/getOptionsFromIdName';

import { messengerIdMaxLength, testIdNamespace } from '../utils';
import { CountriesSelect } from './CountriesSelect';
import { MessengersSelect } from './MessengersSelect';
import { SourceInfoAboutUs } from './SourceInfoAboutUs';
import { r } from 'utils/r';
import { VerticalField } from './VerticalField';

const companyNameMaxLength = 255;
const addressMaxLength = 255;

type Props = {
  countryCode?: string;
  emailAndPasswordFields: ReactNode;
  countriesOptions: SelectOption[];
  messengersOptions: SelectOption[];
  showVerticalField: boolean;
};

export const CompanyProfileForm = ({
  countryCode,
  emailAndPasswordFields,
  countriesOptions,
  messengersOptions,
  showVerticalField,
}: Props) => {
  const { t } = useTranslation('registration');

  return (
    <>
      <Row gutter={16}>
        <Col span={24} md={12}>
          <Form.Item
            name="companyName"
            rules={[
              { required: true, message: t('registerForm.companyNameField.required') },
              {
                max: companyNameMaxLength,
                message: t('registerForm.companyNameField.max', { length: companyNameMaxLength }),
              },
            ]}
          >
            <Input
              type="text"
              placeholder={r(t('registerForm.companyNameField.placeholder'))}
              data-testid={`${testIdNamespace}:companyName`}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item name="countryId" rules={[{ required: true, message: t('registerForm.countrySelect.required') }]}>
            <CountriesSelect options={countriesOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} md={12}>
          <Form.Item
            name="addressFirstLine"
            rules={[
              { max: addressMaxLength, message: t('registerForm.addressFirstLine.max', { length: addressMaxLength }) },
            ]}
          >
            <Input
              type="text"
              placeholder={t('registerForm.addressFirstLine.placeholder')}
              data-testid={`${testIdNamespace}:addressFirstLine`}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="addressSecondLine"
            rules={[
              { max: addressMaxLength, message: t('registerForm.addressSecondLine.max', { length: addressMaxLength }) },
            ]}
          >
            <Input
              type="text"
              placeholder={t('registerForm.addressSecondLine.placeholder')}
              data-testid={`${testIdNamespace}:addressSecondLine`}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Typography.Title level={4} style={{ marginTop: '1em' }}>
            {t('registerForm.contactInfoTitle')}
          </Typography.Title>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="firstName"
            validateTrigger="onBlur"
            rules={[
              { required: true, whitespace: true, message: t('registerForm.firstNameField.required') },
              { pattern: NAME_VALIDATION_PATTERN, message: t('registerForm.namePattern') },
            ]}
          >
            <Input
              type="text"
              placeholder={r(t('registerForm.firstNameField.placeholder'))}
              data-testid={`${testIdNamespace}:firstName`}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="lastName"
            validateTrigger="onBlur"
            rules={[
              { required: true, whitespace: true, message: t('registerForm.lastNameField.required') },
              { pattern: NAME_VALIDATION_PATTERN, message: t('registerForm.namePattern') },
            ]}
          >
            <Input
              type="text"
              placeholder={r(t('registerForm.lastNameField.placeholder'))}
              data-testid={`${testIdNamespace}:lastName`}
            />
          </Form.Item>
        </Col>
      </Row>
      {emailAndPasswordFields}
      <Row gutter={16}>
        <Col span={24} md={12}>
          <Row gutter={4}>
            <Col xs={24} sm={16}>
              <Form.Item
                name="messengerId"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: t('registerForm.messengerIdField.required') },
                  {
                    max: messengerIdMaxLength,
                    message: t('registerForm.messengerIdField.max', { length: messengerIdMaxLength }),
                  },
                  { whitespace: true, message: t('registerForm.messengerIdField.whitespace') },
                ]}
              >
                <Input
                  type="text"
                  placeholder={r(t('registerForm.messengerIdField.placeholder'))}
                  data-testid={`${testIdNamespace}:messengerId`}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                name="messengerTypeId"
                rules={[{ required: true, message: t('registerForm.messengerTypeIdField.required') }]}
              >
                <MessengersSelect options={messengersOptions} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24} md={12}>
          <PhoneFormItem country={countryCode} label="" inputProps={{ placeholder: t('registerForm.phone') }} />
        </Col>
      </Row>
      <Row gutter={16}>
        {showVerticalField && (
          <Col span={24} md={12}>
            <VerticalField />
          </Col>
        )}
        <Col span={24} md={12}>
          <SourceInfoAboutUs />
        </Col>
      </Row>
      <Row>
        <Form.Item name="singUpForNewsletter" valuePropName="checked" noStyle>
          <Checkbox data-testid={`${testIdNamespace}:signUpForNewsletter`}>
            {t('registerForm.singUpForNewsletter')}
          </Checkbox>
        </Form.Item>
      </Row>
    </>
  );
};
