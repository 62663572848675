import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { r } from 'utils/r';
import { testIdNamespace } from '../utils';

const maxLength = 255;

export const VerticalField = () => {
  const { t } = useTranslation('registration', { keyPrefix: 'registerForm.verticalField' });

  return (
    <Form.Item
      name="vertical"
      rules={[
        { message: t('required'), required: true, whitespace: true },
        { max: maxLength, message: t('max', { length: maxLength }) },
      ]}
    >
      <Input placeholder={r(t('placeholder'))} data-testid={`${testIdNamespace}:vertical`} />
    </Form.Item>
  );
};
